import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { publicEnv } from '@lib/env/public-env-vars';

import cartProgressIndicatorReducer from './slices/cartProgressIndicatorSlice';
import categoriesReducer from './slices/categoriesSlice';
import geolocationPermissionsReducer from './slices/geolocationPermissionsSlice';
import landingSliderDropdownReducer from './slices/landingSliderDropdownSlice';
import locationSchedulerReducer from './slices/locationScheduler';
import modalsReducer from './slices/modalsSlice';
import numberAuthReducer from './slices/numberAuthSlice';
import phoneVerificationReducer from './slices/phoneVerificationSlice';
import profileReducer from './slices/profileSlice';
import slotsReducer from './slices/slotsSlice';
import unavailableProductsModalReducer from './slices/unavailableProductsModal';

export const rootReducer = combineReducers({
  geolocationPermissions: geolocationPermissionsReducer,
  categories: categoriesReducer,
  slots: slotsReducer,
  locationScheduler: locationSchedulerReducer,
  landingSliderDropdown: landingSliderDropdownReducer,
  modals: modalsReducer,
  profile: profileReducer,
  phoneVerification: phoneVerificationReducer,
  numberAuth: numberAuthReducer,
  unavailableProductsModal: unavailableProductsModalReducer,
  cartProgressIndicator: cartProgressIndicatorReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: publicEnv.NEXT_PUBLIC_ENVIRONMENT !== 'prod',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
});
