import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentCartCoordinatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentCartCoordinatesQuery = { readonly __typename: 'Query', readonly currentCart: { readonly __typename: 'CustomerCart', readonly shippingAddress: { readonly __typename: 'ShippingAddress', readonly lat: number, readonly long: number } } };


export const CurrentCartCoordinatesDocument = gql`
    query CurrentCartCoordinates {
  currentCart {
    shippingAddress {
      lat
      long
    }
  }
}
    `;

/**
 * __useCurrentCartCoordinatesQuery__
 *
 * To run a query within a React component, call `useCurrentCartCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCartCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCartCoordinatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCartCoordinatesQuery(baseOptions?: Apollo.QueryHookOptions<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>(CurrentCartCoordinatesDocument, options);
      }
export function useCurrentCartCoordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>(CurrentCartCoordinatesDocument, options);
        }
export function useCurrentCartCoordinatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>(CurrentCartCoordinatesDocument, options);
        }
export type CurrentCartCoordinatesQueryHookResult = ReturnType<typeof useCurrentCartCoordinatesQuery>;
export type CurrentCartCoordinatesLazyQueryHookResult = ReturnType<typeof useCurrentCartCoordinatesLazyQuery>;
export type CurrentCartCoordinatesSuspenseQueryHookResult = ReturnType<typeof useCurrentCartCoordinatesSuspenseQuery>;
export type CurrentCartCoordinatesQueryResult = Apollo.QueryResult<CurrentCartCoordinatesQuery, CurrentCartCoordinatesQueryVariables>;