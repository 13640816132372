import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type CustomerShippingAddressCoordinatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerShippingAddressCoordinatesQuery = { readonly __typename: 'Query', readonly customerShippingAddress?: { readonly __typename: 'CustomerShippingAddress', readonly id: string, readonly shippingAddress: { readonly __typename: 'ShippingAddress', readonly lat: number, readonly long: number } } | null };


export const CustomerShippingAddressCoordinatesDocument = gql`
    query CustomerShippingAddressCoordinates {
  customerShippingAddress {
    id
    shippingAddress {
      lat
      long
    }
  }
}
    `;

/**
 * __useCustomerShippingAddressCoordinatesQuery__
 *
 * To run a query within a React component, call `useCustomerShippingAddressCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerShippingAddressCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerShippingAddressCoordinatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerShippingAddressCoordinatesQuery(baseOptions?: Apollo.QueryHookOptions<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>(CustomerShippingAddressCoordinatesDocument, options);
      }
export function useCustomerShippingAddressCoordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>(CustomerShippingAddressCoordinatesDocument, options);
        }
export function useCustomerShippingAddressCoordinatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>(CustomerShippingAddressCoordinatesDocument, options);
        }
export type CustomerShippingAddressCoordinatesQueryHookResult = ReturnType<typeof useCustomerShippingAddressCoordinatesQuery>;
export type CustomerShippingAddressCoordinatesLazyQueryHookResult = ReturnType<typeof useCustomerShippingAddressCoordinatesLazyQuery>;
export type CustomerShippingAddressCoordinatesSuspenseQueryHookResult = ReturnType<typeof useCustomerShippingAddressCoordinatesSuspenseQuery>;
export type CustomerShippingAddressCoordinatesQueryResult = Apollo.QueryResult<CustomerShippingAddressCoordinatesQuery, CustomerShippingAddressCoordinatesQueryVariables>;