import { useShippingAddressCoordinates } from './useShippingAddressCoordinates';

export const useUserAddressCoordinates = () => {
  const coordinates = useShippingAddressCoordinates();

  return {
    loading: coordinates.loading,
    coordinates: coordinates.data || undefined,
    error: coordinates.error,
  };
};
